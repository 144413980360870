<template>
  <div class="process">
    <Brand />
    <BackBtn
        v-if="route.query.processBack !== '0'"
        @click="back()"
        :title="route.params.section === 'sales' ? 'Zum Sales-Bereich' : 'Zum Aftersales-Bereich'" />
    <div class="subnav-container">
      <h1
          :class="{ active: state.section === 'description' }"
          @click="toggleContent('description')">
        <b>{{ route.params.section === 'sales' ? 'Sales: ' : 'Aftersales: ' }}</b>
        {{ state.process.title }}
        <b>- Beschreibung</b>
        <div
            class="touchpoint"
            v-if="state.section !== 'description'">
          <TouchpointSimple @click="toggleContent('description')" />
        </div>
      </h1>
      <ul class="subnav">
        <li :class="{ active: state.section === 'aims' }">
          <span class="id" v-text="1" />
          <span class="title">
            <b>Ziele</b>
            <br><small>Worum geht es?</small>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'aims'">
            <TouchpointSimple @click="toggleContent('aims')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'more' }">
          <span class="id" v-text="2" />
          <span class="title">
            <b>Prozess im Wandel</b>
            <br><small>Veränderungstreiber, Anwendung und Vorteile</small>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'more'">
            <TouchpointSimple @click="toggleContent('more')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'innovations' }">
          <span class="id" v-text="3" />
          <span class="title">
            <b>Blick in die Zukunft</b>
            <br><small>Entdecken Sie Innovationsfelder!</small>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'innovations'">
            <TouchpointSimple @click="toggleContent('innovations')" />
          </div>
        </li>
      </ul>
      <transition
          enter-active-class="animated slideInUp"
          leave-active-class="animated slideOutDown"
          mode="out-in"
          appear>
        <div class="content">
          <div
              class="image"
              :style="{
                backgroundImage: state.process.image
                  ? `url(${state.basePath}images/${state.process.image})`
                  : `url(${require('@/assets/img/placeholder.png')})`
              }" />
          <transition
              name="slideRight"
              mode="out-in">
            <div
                class="body"
                :key="state.section">
              <div
                  class="description"
                  v-if="state.section === 'description'">
                <h2>Beschreibung</h2>
                <div v-html="state.process.description" />
                <template v-if="state.process.keyfacts">
                <h2 v-if="state.process.keyfacts.length > 0">
                  Keyfacts
                </h2>
                <ul v-if="state.process.keyfacts.length > 0">
                  <li
                      v-for="(fact, idx) in state.process.keyfacts"
                      :key="idx"
                      v-text="fact" />
                </ul>
                </template>
              </div>
              <div
                  class="aims"
                  v-if="state.section === 'aims'">
                <h2>Ziele</h2>
                <div v-html="state.process.aims" />
              </div>
              <div
                  class="more"
                  v-if="state.section === 'more'">
                <h2>Veränderungstreiber</h2>
                <div v-html="state.process.changes" />
                <h2>Anwendung</h2>
                <div v-html="state.process.usage" />
                <h2>Vorteile</h2>
                <div v-html="state.process.advantages" />
              </div>
              <div
                  class="innovations"
                  v-if="state.section === 'innovations'">
                <h2>Innovationsfelder ({{ state.process.innovations.length }})</h2>
                <ul>
                  <transition-group
                      name="fade"
                      appear>
                    <li
                        v-for="(innovation, idx) in state.process.innovations"
                        :key="innovation.id"
                        :style="{ animationDelay: `${idx * 0.25}s` }"
                        @click="goToInnovation(innovation.id)">
                      <img
                          :src="`${state.basePath}images/${innovation.image}`"
                          :alt="innovation.title"
                          v-if="innovation.image">
                      <img
                          src="../assets/img/placeholder.png"
                          :alt="innovation.title"
                          v-if="!innovation.image">
                      <span class="id" v-text="idx + 1" />
                      <span class="title" v-text="innovation.title" />
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Brand from '@/components/Brand'
import BackBtn from '@/components/BackBtn'
import TouchpointSimple from '@/components/TouchpointSimple'

export default {
  name: 'Autohaus',

  components: {
    Brand,
    BackBtn,
    TouchpointSimple
  },

  setup () {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const axios    = instance.appContext.config.globalProperties.axios
    const router   = useRouter()
    const route    = useRoute()

    const state = reactive({
      publicPath: process.env.BASE_URL,
      basePath: process.env.VUE_APP_API_ENDPOINT,
      process: {},
      section: 'description'
    })

    const getProcess = async (pid) => {
      emitter.emit('loading-start')
      await axios
        .all([
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}innovations`),
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}processes/${pid}`)
        ])
        .then(axios.spread((...responses) => {
          state.process = responses[1].data
          state.process.innovations =
            responses[0].data
              .filter(item => responses[1].data.innovations.includes(item.id))
          emitter.emit('loading-stop')
      }))
    }

    const back = () => {
      emitter.emit('play-btn-sound')
      let section
      if (route.params.section === 'sales') section = 'Sales'
      else section = 'Aftersales'
      router.push({
        name: section,
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const toggleContent = (section) => {
      emitter.emit('play-btn-sound')
      state.section = section
    }

    const goToInnovation = (iid) => {
      emitter.emit('play-btn-sound')
      let section
      if (route.params.section === 'sales') section = 'sales'
      else section = 'aftersales'
      router.push({
        name: 'Innovationsfeld',
        params: {
          section,
          pid: state.process.id,
          iid
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    onMounted(() => getProcess(route.params.pid))

    return {
      back,
      route,
      state,
      toggleContent,
      goToInnovation
    }
  }
}
</script>


<style lang="scss" scoped>
.process {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(to top, #fff 0%, #85a7c2 100%);
  background: url('/static/img/bg-werkstatt.jpg') no-repeat center center;
  background-size: cover;

  .subnav-container {
    position: absolute;
    left: 0;
    top: 12vh;
    width: 100%;
    height: calc(100% - 12vh);
    display: flex;
    flex-direction: column;

    > h1 {
      width: 100%;
      background: rgba($color: #5b6e7f, $alpha: 0.95);
      color: #fff;
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
      letter-spacing: -0.5pt;
      transition: all 250ms ease-in-out;
      cursor: pointer;

      b {
        margin-right: 1rem;
        font-weight: 200;
      }

      &.active {
        background: rgba($color: #333, $alpha: 0.95);
        box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
      }

      .touchpoint {
        transition: all 500ms ease-in-out;
        position: absolute;
        top: -1.25rem;
        left: calc(50% - 1.25rem);

        &:hover {
          transform: scale(1.5);
        }
      }
    }

    .subnav {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 10;

      li {
        width: 100%;
        background: rgba($color: #5b6e7f, $alpha: 0.95);
        color: #fff;
        padding: 1.5rem 1.5rem 2.5rem 1.5rem;
        border-right: 1px solid rgba($color: #fff, $alpha: 0.25);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        transition: all 500ms ease-in-out;

        &:last-child {
          border-right: none;
        }

        &.active {
          background: rgba($color: #333, $alpha: 0.95);
          box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
          z-index: 10;
        }

        span {
          height: 100%;
          text-align: center;
        }

        .id {
          position: absolute;
          font-family: 'Navada Outline', sans-serif;
          font-size: 6rem;
          height: 6rem;
          line-height: 1em;
          left: 15%;
          bottom: -0.045em;
          color: rgba($color: #fff, $alpha: 0.1);
        }

        .title {
          font-size: 1.2rem;
          font-weight: 600;

          small {
            font-weight: 400;
            color: #c1e2f1;
          }
        }

        .touchpoint {
          transition: all 500ms ease-in-out;
          position: absolute;
          bottom: -1.25rem;

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }

    .content {
      color: #fff;
      display: flex;
      overflow: hidden;
      height: 100%;
      line-height: 1.5rem;
      animation-duration: 350ms;
      background: linear-gradient(180deg, rgba($color: #5b6e7f, $alpha: 0.95) 0%, rgba($color: #415566, $alpha: 0.95));

      .image {
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .body {
        width: 50%;
        height: 100%;
        padding: 4rem;
        overflow: auto;
        animation-duration: 350ms;

        h2 {
          margin: 4rem 0 1.5rem 0;
          font-size: 1.75rem;

          &:first-child {
            margin-top: 0;
          }
        }

        ::v-deep() p,
        ::v-deep() ul {
          margin-bottom: 2em;

          b {
            font-weight: 900;
          }
        }

        ::v-deep() ul {
          margin-left: 1.25rem;

          li {
            margin: 1rem 0;

            &::marker {
              font-size: 1.5rem;
              color: orange;
            }
          }
        }

        ::v-deep() sup {
          display: inline-block;
          font-size: 0.75rem;
          font-weight: 200;
          margin: -0.5em 0.25em 0 0.25em;
        }

        ::v-deep() a {
          color: rgb(181, 222, 226);

          &:hover {
            color: #fff;
          }
        }

        ::v-deep() .ql-size-small {
          display: block;
          font-size: 0.7rem;
          line-height: 1.25em;
          margin-bottom: -1.25rem;
          padding-bottom: 0.5rem;
          color: rgba($color: #fff, $alpha: 0.5);
          border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        }

        .innovations {
          ::v-deep() ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;

            li {
              display: flex;
              flex-direction: column;
              margin: 0;
              padding: 0;
              min-width: 100%;
              overflow: hidden;
              border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
              position: relative;
              cursor: pointer;
              transition: all 250ms ease-in-out;

              &:hover {
                opacity: 0.5;
              }

              img {
                width: 100%;
                display: block;
                clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
              }

              .title {
                display: block;
                padding: 0.75rem 0;
                flex-grow: 1;
                line-height: 1.25rem;
              }

              .id {
                position: absolute;
                width: 2rem;
                height: 2rem;
                left: 1rem;
                top: 1rem;
                border-radius: 1rem;
                background-color: #fff;
                color: #333;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }
}
</style>
