<template>
  <div class="brands">
    <svg
        @click="goBw()"
        viewBox="0 0 160.62 68.03"
        class="bw">
      <rect class="st1" x="1.17" y="22.23" width="21.85" height="21.85"/>
      <path class="st0" d="M141.51,37.21c-0.06-0.01-0.18,0.16-0.35,0.27c-0.15-0.09-0.24-0.95-0.21-1.06c0.03-0.12,1.2-0.71,2.62-0.68 c0.34,0,0.84,0.1,1.02,0.11c0.35,0.01,0.83-0.33,1.03-0.3c0.05,0.12-0.24,0.2-0.28,0.64c0.63,0.48,0.89,1.74,1.06,2.34 c0.2,0.2,0.99,0.32,1.23,0.39c1.83,0.5,2.88,0.05,5.11-0.52c2.57-0.65,4.13,0.34,4.53-0.81c0.1-0.28,0.1-0.62-0.35-0.9 c-0.71-0.44-1.93-0.42-3.92,0.31c-1.14,0.42-2.71,1.23-4.26,1.27c-0.87,0.02-1.6-0.69-1.57-1.43c0.05-0.91,1.53-1.59,1.67-1.45 c0.09,0.09-1.09,1.05-0.52,1.82c0.45,0.61,1.5,0.35,1.94,0.22c1.65-0.47,5.05-2.31,6.76-1.42c1.51,0.8,1.31,2.23,0.09,2.74 c0.59,0.64,0.92,1.92,0.98,2.78c0.01,0,0.48,0.22,1.52,0.42l-0.1,2.06h-2.59c-0.01-0.77,1.2-0.72,1.35-0.96 c-0.81-0.23-1.96-0.56-3.25-1.68c-0.88,0.88-1.32,2.52-1.32,2.52h-3.34c0.01-0.23,0.44-0.95,2-0.83c-0.03-0.75,0.09-1.96,0.14-2.36 c-1.72-0.02-2.2,2.05-5.74,1.83c-0.02,0.11-0.22,0.41-0.22,0.41s-3.88,1.14-3.98,1.14h-3.94c0-0.4-0.04-1.3,2.25-0.88 c0,0,1.65-1.13,1.75-1.22L139.35,40c-0.02-0.07-0.23-0.41-0.53-0.46c-0.29-0.05-0.95-0.03-0.95-0.03c-0.1-1.21,1.68-1.41,2.38-0.42 c0.19,0.1,1.74,0.28,2.03,0.31c0.05-0.27,0.08-0.67,0.12-0.79c-0.09-0.02-0.86,0.47-0.93,0.28c-0.11-0.32-0.24-0.87-0.11-0.81 c0.23,0.3,0.56,0.19,0.56-0.13c-1.73-0.01-2.2,0.21-2.18-1.51c0.02-0.12,0.12-0.15,0.15,0.01c0.23,0.95,0.41,1.39,1.94,1.12 C141.85,37.46,141.85,37.33,141.51,37.21 M142.39,36.19c-0.02,0.11,0,0.31,0.05,0.33c0.08,0.03,0.44-0.37,0.44-0.37 C142.58,36.13,142.39,36.19,142.39,36.19"/>
      <path class="st0" d="M40.23,24.91c0,0.37-0.19,0.55-0.56,0.55c-0.04,0-0.14,0-0.28,0c-0.14,0-0.26,0-0.36,0 c-1.15,0-1.72,0.29-1.72,0.86v4.13c0,0.28-0.1,0.45-0.31,0.52c-0.08,0.03-0.3,0.04-0.66,0.04c-0.37,0-0.55-0.19-0.55-0.57 l0.02-4.14c0-1.41,1.29-2.11,3.86-2.11C40.04,24.2,40.23,24.43,40.23,24.91z"/>
      <path class="st0" d="M49.77,28.96c0,1.36-1.02,2.04-3.06,2.04h-3.65c-0.73,0-1.41-0.15-2.03-0.45c-0.77-0.37-1.16-0.88-1.16-1.52 v-0.86c0-0.71,0.37-1.24,1.11-1.6c0.57-0.27,1.25-0.41,2.04-0.41h5.29c0-0.48-0.61-0.62-1.89-0.62c-0.14,0-3.13-0.02-3.17-0.02 c-0.37,0-0.55-0.19-0.55-0.55v-0.15c0-0.37,0.18-0.56,0.55-0.56c1.25,0,4.32-0.08,5.01,0.11c1.09,0.31,1.52,0.99,1.52,1.92V28.96z M48.27,27.41h-5.3c-0.31,0-0.63,0.06-0.96,0.17c-0.43,0.14-0.64,0.33-0.64,0.58v0.86c0,0.25,0.22,0.44,0.67,0.57 c0.33,0.1,0.66,0.16,0.98,0.16h3.71c0.67,0,1.1-0.09,1.31-0.26c0.18-0.15,0.27-0.44,0.27-0.88c0-0.1-0.01-0.27-0.01-0.54 C48.28,27.81,48.27,27.59,48.27,27.41"/>
      <path class="st0" d="M34.72,24.95c0,0.37-0.19,0.55-0.56,0.55h-2.37v4.95c0,0.37-0.18,0.56-0.55,0.56c-0.35,0-0.56-0.02-0.65-0.05 c-0.2-0.07-0.3-0.24-0.3-0.51V25.5h-0.53c-0.39,0-0.58-0.21-0.58-0.62c0-0.42,0.19-0.62,0.58-0.62h0.53v-1.34 c0-0.37,0.18-0.55,0.55-0.55c0.35,0,0.57,0.01,0.65,0.04c0.2,0.07,0.3,0.24,0.3,0.51v1.34h2.37 C34.53,24.25,34.72,24.49,34.72,24.95z"/>
      <path class="st0" d="M60.9,30.45c0,0.37-0.19,0.56-0.56,0.56c-0.35,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.31-0.24-0.31-0.52v-4.33 c0-0.32-0.26-0.52-0.77-0.61c-0.18-0.03-0.56-0.04-1.12-0.04h-2.44c-0.59,0-0.97,0.01-1.16,0.04c-0.53,0.09-0.79,0.3-0.79,0.62 v4.33c0,0.37-0.18,0.56-0.55,0.56c-0.35,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.3-0.24-0.3-0.52v-4.31c0-1.29,1.15-1.94,3.44-1.94h2.45 c2.27,0,3.4,0.64,3.4,1.93V30.45z"/>
      <path class="st0" d="M76.95,24.81c0,0.46-0.18,0.7-0.55,0.7h-2.29v4.95c0,0.37-0.18,0.56-0.55,0.56c-0.35,0-0.56-0.01-0.65-0.04 c-0.2-0.07-0.3-0.24-0.3-0.52v-6.09c0-1.34,1.12-2.01,3.36-2.01c0.14,0,0.28,0,0.43,0.01c0.23,0,0.39,0.07,0.47,0.19 c0.06,0.09,0.09,0.26,0.09,0.5c0,0.37-0.18,0.55-0.55,0.55c-0.7,0-1.14,0.01-1.31,0.04c-0.63,0.08-0.95,0.29-0.95,0.62h2.26 C76.76,24.25,76.95,24.44,76.95,24.81z"/>
      <path class="st0" d="M87.04,28.89c0,0.77-0.38,1.34-1.15,1.71c-0.58,0.27-1.31,0.41-2.17,0.41h-2.49c-0.86,0-1.6-0.14-2.22-0.42 c-0.83-0.37-1.25-0.94-1.25-1.7v-2.51c0-0.75,0.41-1.33,1.24-1.73c0.65-0.31,1.39-0.46,2.22-0.46h2.5c2.21,0,3.32,0.72,3.32,2.17 V28.89z M85.52,28.89v-2.62c0-0.54-0.62-0.81-1.85-0.81h-2.43c-0.45,0-0.86,0.06-1.22,0.17c-0.51,0.17-0.77,0.42-0.77,0.75v2.51 c0,0.33,0.26,0.56,0.79,0.71c0.35,0.1,0.75,0.15,1.21,0.15h2.43c0.4,0,0.77-0.06,1.11-0.17C85.28,29.43,85.52,29.2,85.52,28.89z"/>
      <path class="st0" d="M93.26,24.91c0,0.37-0.19,0.55-0.56,0.55c-0.04,0-0.14,0-0.28,0c-0.14,0-0.26,0-0.36,0 c-1.15,0-1.72,0.29-1.72,0.86v4.13c0,0.28-0.1,0.45-0.31,0.52c-0.08,0.03-0.3,0.04-0.66,0.04c-0.37,0-0.55-0.19-0.55-0.57 l0.02-4.14c0-1.41,1.29-2.11,3.86-2.11C93.07,24.2,93.26,24.43,93.26,24.91z"/>
      <path class="st0" d="M107.36,30.45c0,0.37-0.19,0.56-0.55,0.56c-0.35,0-0.56-0.01-0.64-0.04c-0.2-0.07-0.3-0.24-0.3-0.52v-4.2 c0-0.54-0.64-0.81-1.91-0.81c-0.12,0-0.27,0-0.44,0.01c-0.17,0.01-0.28,0.01-0.32,0.01c-1,0-1.5,0.33-1.5,0.98v4.01 c0,0.27-0.1,0.45-0.3,0.52c-0.08,0.03-0.3,0.04-0.64,0.04c-0.38,0-0.56-0.19-0.56-0.56v-4.02c0-0.4-0.14-0.68-0.43-0.82 c-0.2-0.1-0.53-0.15-0.98-0.15c-0.04,0-0.16,0-0.36-0.01c-0.2-0.01-0.36-0.01-0.5-0.01c-1.29,0-1.93,0.27-1.93,0.82v4.19 c0,0.27-0.1,0.45-0.3,0.52c-0.08,0.03-0.3,0.04-0.65,0.04c-0.37,0-0.55-0.19-0.55-0.56v-4.03c0-1.48,1.09-2.22,3.26-2.22 c0.91,0,1.46,0.01,1.67,0.04c0.54,0.07,1.06,0.25,1.55,0.55c0.46-0.28,0.99-0.46,1.57-0.53c0.27-0.03,0.83-0.05,1.67-0.05 c2.1,0,3.14,0.74,3.14,2.22V30.45z"/>
      <path class="st0" d="M125.25,24.95c0,0.37-0.19,0.55-0.56,0.55h-2.37v4.95c0,0.37-0.18,0.56-0.55,0.56c-0.34,0-0.56-0.02-0.65-0.05 c-0.2-0.07-0.3-0.24-0.3-0.51V25.5h-0.53c-0.39,0-0.58-0.21-0.58-0.62c0-0.42,0.19-0.62,0.58-0.62h0.53v-1.34 c0-0.37,0.18-0.55,0.55-0.55c0.35,0,0.57,0.01,0.65,0.04c0.2,0.07,0.3,0.24,0.3,0.51v1.34h2.37 C125.06,24.25,125.25,24.49,125.25,24.95z"/>
      <path class="st0" d="M138.99,28.89c0,0.77-0.38,1.34-1.15,1.71c-0.58,0.27-1.31,0.41-2.17,0.41h-2.49c-0.86,0-1.6-0.14-2.22-0.42 c-0.83-0.37-1.25-0.94-1.25-1.7v-2.51c0-0.75,0.41-1.33,1.24-1.73c0.65-0.31,1.39-0.46,2.22-0.46h2.5c2.21,0,3.32,0.72,3.32,2.17 V28.89z M137.48,28.89v-2.62c0-0.54-0.62-0.81-1.85-0.81h-2.43c-0.45,0-0.86,0.06-1.22,0.17c-0.51,0.17-0.77,0.42-0.77,0.75v2.51 c0,0.33,0.26,0.56,0.79,0.71c0.35,0.1,0.75,0.15,1.21,0.15h2.43c0.4,0,0.77-0.06,1.11-0.17C137.23,29.43,137.48,29.2,137.48,28.89z "/>
      <path class="st0" d="M149.64,30.45c0,0.37-0.19,0.56-0.56,0.56c-0.35,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.3-0.24-0.3-0.52v-4.33 c0-0.32-0.26-0.52-0.77-0.61c-0.18-0.03-0.56-0.04-1.12-0.04h-2.44c-0.59,0-0.97,0.01-1.16,0.04c-0.53,0.09-0.79,0.3-0.79,0.62 v4.33c0,0.37-0.18,0.56-0.55,0.56c-0.34,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.3-0.24-0.3-0.52v-4.31c0-1.29,1.14-1.94,3.43-1.94h2.45 c2.27,0,3.4,0.64,3.4,1.93V30.45z"/>
      <path class="st0" d="M71.24,29.06c0,1.3-0.99,1.94-2.96,1.94h-5.05c-0.37,0-0.55-0.19-0.55-0.56c0-0.46,0.18-0.69,0.55-0.69h5.06 c0.93,0,1.4-0.23,1.4-0.69v-0.26c0-0.41-0.42-0.62-1.27-0.62h-2.43c-2.23,0-3.35-0.62-3.35-1.87v-0.37c0-0.83,0.57-1.36,1.71-1.6 c0.46-0.09,1.33-0.14,2.62-0.14c0.37,0,0.56,0.19,0.56,0.56v0.15c0,0.37-0.19,0.55-0.56,0.55h-1.08c-0.55,0-0.89,0.01-1.01,0.02 c-0.5,0.06-0.75,0.21-0.75,0.46v0.35c0,0.29,0.26,0.48,0.77,0.56c0.19,0.03,0.56,0.05,1.09,0.05h2.48c1.84,0,2.77,0.61,2.77,1.84 V29.06z"/>
      <path class="st0" d="M159.63,29.06c0,1.3-0.99,1.94-2.96,1.94h-5.05c-0.37,0-0.55-0.19-0.55-0.56c0-0.46,0.18-0.69,0.55-0.69h5.06 c0.93,0,1.4-0.23,1.4-0.69v-0.26c0-0.41-0.42-0.62-1.27-0.62h-2.43c-2.23,0-3.35-0.62-3.35-1.87v-0.37c0-0.83,0.57-1.36,1.71-1.6 c0.46-0.09,1.33-0.14,2.62-0.14c0.37,0,0.56,0.19,0.56,0.56v0.15c0,0.37-0.19,0.55-0.56,0.55h-1.08c-0.55,0-0.89,0.01-1.01,0.02 c-0.5,0.06-0.75,0.21-0.75,0.46v0.35c0,0.29,0.26,0.48,0.77,0.56c0.19,0.03,0.56,0.05,1.09,0.05h2.48c1.84,0,2.77,0.61,2.77,1.84 V29.06z"/>
      <path class="st0" d="M118.81,28.96c0,1.36-1.02,2.04-3.06,2.04h-3.65c-0.73,0-1.41-0.15-2.03-0.45c-0.77-0.37-1.16-0.88-1.16-1.52 v-0.86c0-0.71,0.37-1.24,1.11-1.6c0.57-0.27,1.25-0.41,2.04-0.41h5.29c0-0.48-0.61-0.62-1.89-0.62c-0.14,0-3.13-0.02-3.17-0.02 c-0.37,0-0.55-0.19-0.55-0.55v-0.15c0-0.37,0.18-0.56,0.55-0.56c1.25,0,4.32-0.08,5.01,0.11c1.09,0.31,1.52,0.99,1.52,1.92V28.96z M117.32,27.41h-5.3c-0.31,0-0.63,0.06-0.96,0.17c-0.43,0.14-0.64,0.33-0.64,0.58v0.86c0,0.25,0.22,0.44,0.67,0.57 c0.33,0.1,0.66,0.16,0.98,0.16h3.71c0.67,0,1.1-0.09,1.31-0.26c0.18-0.15,0.27-0.44,0.27-0.88c0-0.1-0.01-0.27-0.01-0.54 C117.32,27.81,117.32,27.59,117.32,27.41"/>
      <path class="st0" d="M128.18,30.43c0,0.38-0.19,0.57-0.57,0.57c-0.35,0-0.56-0.02-0.65-0.05c-0.2-0.07-0.29-0.24-0.29-0.52v-5.21 c0-0.37,0.19-0.56,0.57-0.56c0.34,0,0.56,0.01,0.65,0.04c0.2,0.07,0.3,0.24,0.3,0.52V30.43z"/>
      <path class="st0" d="M128.39,23c0,0.24-0.1,0.44-0.31,0.58c-0.18,0.13-0.41,0.19-0.68,0.19c-0.27,0-0.49-0.06-0.67-0.19 c-0.21-0.14-0.31-0.34-0.31-0.58c0-0.24,0.1-0.44,0.3-0.58c0.19-0.13,0.41-0.19,0.68-0.19c0.26,0,0.49,0.06,0.68,0.19 C128.28,22.56,128.39,22.76,128.39,23"/>
      <path class="st0" d="M42.88,38.18l-2.77,5.39c-0.17,0.32-0.47,0.48-0.91,0.48c-0.42,0-0.72-0.15-0.88-0.46l-2.21-4.14l-2.24,4.15 c-0.17,0.31-0.48,0.47-0.91,0.47c-0.45,0-0.76-0.16-0.93-0.49l-2.72-5.39c-0.07-0.13-0.1-0.25-0.1-0.35c0-0.32,0.23-0.48,0.7-0.48 c0.41,0,0.68,0.11,0.8,0.33l2.3,4.45l2.24-4.14c0.18-0.33,0.47-0.5,0.87-0.5c0.39,0,0.68,0.16,0.85,0.49l2.24,4.15l2.29-4.45 c0.11-0.22,0.37-0.33,0.79-0.33c0.47,0,0.71,0.16,0.71,0.48C42.99,37.93,42.95,38.05,42.88,38.18z"/>
      <path class="st0" d="M87.93,43.52c0,0.37-0.19,0.56-0.56,0.56c-0.35,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.31-0.24-0.31-0.52v-4.33 c0-0.32-0.26-0.52-0.77-0.61c-0.18-0.03-0.56-0.04-1.12-0.04h-2.44c-0.59,0-0.97,0.01-1.16,0.04c-0.53,0.09-0.79,0.3-0.79,0.62 v4.33c0,0.37-0.18,0.56-0.55,0.56c-0.35,0-0.56-0.01-0.65-0.04c-0.2-0.07-0.3-0.24-0.3-0.52V39.2c0-1.29,1.15-1.94,3.44-1.94h2.45 c2.27,0,3.4,0.64,3.4,1.93V43.52z"/>
      <path class="st0" d="M104.66,41.78c0,0.82-0.39,1.43-1.18,1.83c-0.61,0.31-1.37,0.47-2.29,0.47h-6.69c-0.37,0-0.55-0.19-0.55-0.56 v-7.54c0-0.37,0.18-0.55,0.55-0.55h6.49c0.73,0,1.29,0.04,1.67,0.13c0.58,0.13,1.06,0.39,1.45,0.78c0.36,0.36,0.54,0.79,0.54,1.27 c0,0.57-0.02,0.92-0.06,1.07c-0.05,0.19-0.22,0.5-0.5,0.9c-0.09,0.14-0.09,0.26,0,0.36c0.27,0.29,0.44,0.58,0.5,0.85 C104.64,40.93,104.66,41.27,104.66,41.78z M103.08,41.26c-0.01-0.55-0.66-0.82-1.95-0.82h-5.59v2.34h5.49 c1.27,0,1.95-0.27,2.04-0.82C103.08,41.87,103.09,41.63,103.08,41.26z M103.12,38.34c0-0.08-0.02-0.32-0.04-0.72 c-0.03-0.6-0.71-0.9-2.04-0.9h-5.47v2.43h5.53C102.47,39.14,103.14,38.87,103.12,38.34z"/>
      <path class="st0" d="M121.42,36.21l-3.41,7.22c-0.2,0.43-0.52,0.65-0.98,0.65c-0.43,0-0.74-0.2-0.93-0.61l-2.73-5.83l-2.82,5.85 c-0.17,0.35-0.47,0.52-0.92,0.52c-0.48,0-0.8-0.18-0.97-0.55l-3.4-7.24c-0.04-0.09-0.06-0.17-0.06-0.24 c0-0.18,0.09-0.32,0.27-0.43c0.16-0.1,0.35-0.16,0.58-0.16c0.34,0,0.57,0.12,0.68,0.35l2.91,6.18l2.77-5.94 c0.19-0.43,0.51-0.64,0.95-0.64c0.16,0,0.33,0.06,0.52,0.18c0.19,0.12,0.32,0.25,0.39,0.4l2.81,6l2.87-6.18 c0.11-0.24,0.35-0.35,0.74-0.35c0.22,0,0.4,0.05,0.55,0.14c0.17,0.1,0.26,0.24,0.26,0.41C121.49,36.03,121.47,36.12,121.42,36.21z"/>
      <path class="st0" d="M56.59,42.14c0,1.3-0.99,1.94-2.96,1.94h-5.05c-0.37,0-0.55-0.19-0.55-0.56c0-0.46,0.18-0.69,0.55-0.69h5.06 c0.93,0,1.4-0.23,1.4-0.69v-0.26c0-0.41-0.42-0.62-1.27-0.62h-2.43c-2.23,0-3.35-0.62-3.35-1.87v-0.37c0-0.83,0.57-1.36,1.71-1.6 c0.46-0.09,1.33-0.14,2.62-0.14c0.37,0,0.56,0.19,0.56,0.56v0.15c0,0.37-0.19,0.55-0.56,0.55h-1.08c-0.55,0-0.89,0.01-1.01,0.02 c-0.5,0.06-0.75,0.21-0.75,0.46v0.35c0,0.29,0.26,0.48,0.77,0.56c0.19,0.03,0.56,0.05,1.09,0.05h2.48c1.84,0,2.77,0.61,2.77,1.84 V42.14z"/>
      <path class="st0" d="M66.54,42.14c0,1.3-0.99,1.94-2.96,1.94h-5.05c-0.37,0-0.55-0.19-0.55-0.56c0-0.46,0.18-0.69,0.55-0.69h5.06 c0.93,0,1.4-0.23,1.4-0.69v-0.26c0-0.41-0.42-0.62-1.27-0.62h-2.43c-2.23,0-3.35-0.62-3.35-1.87v-0.37c0-0.83,0.57-1.36,1.71-1.6 c0.46-0.09,1.33-0.14,2.62-0.14c0.37,0,0.56,0.19,0.56,0.56v0.15c0,0.37-0.19,0.55-0.56,0.55h-1.08c-0.55,0-0.89,0.01-1.01,0.02 c-0.5,0.06-0.75,0.21-0.75,0.46v0.35c0,0.29,0.26,0.48,0.77,0.56c0.19,0.03,0.56,0.05,1.09,0.05h2.48c1.84,0,2.77,0.61,2.77,1.84 V42.14z"/>
      <path class="st0" d="M77.28,40.79c0,0.41-0.14,0.62-0.42,0.62h-7.43c-0.05,0.95,0.71,1.42,2.29,1.42h0.4h3.12 c0.37,0,0.55,0.23,0.55,0.69c0,0.37-0.18,0.56-0.55,0.56c-0.97,0-4.38-0.02-4.71-0.05c-0.83-0.08-1.43-0.27-1.82-0.58 c-0.55-0.43-0.83-0.94-0.83-1.52v-2.5c0-0.78,0.43-1.36,1.29-1.74c0.63-0.28,1.39-0.42,2.29-0.42h2.79c0.74,0,1.39,0.13,1.94,0.39 c0.72,0.34,1.08,0.83,1.08,1.5V40.79z M75.82,40.11v-0.83c0-0.51-1.01-0.76-3.04-0.76c-0.25,0-0.51,0-0.78,0.01 c-0.27,0.01-0.39,0.02-0.36,0.02c-1.51,0-2.26,0.26-2.26,0.79v0.78H75.82z"/>
      <path class="st0" d="M46.19,43.51c0,0.38-0.19,0.57-0.57,0.57c-0.35,0-0.56-0.02-0.65-0.05c-0.2-0.07-0.29-0.24-0.29-0.52V38.3 c0-0.37,0.19-0.56,0.57-0.56c0.34,0,0.56,0.01,0.65,0.04c0.2,0.07,0.3,0.24,0.3,0.52V43.51z"/>
      <path class="st0" d="M46.4,36.08c0,0.24-0.1,0.44-0.31,0.58c-0.18,0.13-0.41,0.19-0.68,0.19c-0.27,0-0.49-0.06-0.67-0.19 c-0.21-0.14-0.31-0.34-0.31-0.58c0-0.24,0.1-0.44,0.3-0.58c0.19-0.13,0.41-0.19,0.68-0.19c0.26,0,0.49,0.06,0.68,0.19 C46.3,35.64,46.4,35.83,46.4,36.08"/>
    </svg>
    <svg
        @click="goIfa()"
        viewBox="0 0 2637.92 1296.18"
        class="ifa">
      <polygon class="st0" points="953.23,619.99 953.23,602.61 1034.83,504.01 1034.83,503.05 954.19,503.05 954.19,486.23 1057.57,486.23 1057.57,503.43 976.93,602.23 976.93,603.18 1058.52,603.18 1058.52,619.99"/>
      <path class="st0" d="M1077.37,564v-77.76h20.45v79.29c0,23.89,12.03,40.33,35.16,40.33c22.73,0.18,35.16-16.63,35.16-40.33v-79.29 h20.06V564c0,34.58-18.35,58.66-55.22,58.66C1096.67,622.66,1077.37,598.59,1077.37,564"/>
      <polygon class="st0" points="1215.54,619.99 1215.54,486.23 1236,486.23 1236,550.45 1236.95,550.45 1295.8,486.23 1319.68,486.23 1258.74,552.73 1319.68,620.38 1295.8,620.38 1236.95,555.03 1236,555.03 1236,619.99"/>
      <path class="st0" d="M1333.81,564v-77.76h20.44v79.29c0,23.89,12.04,40.33,35.16,40.33c22.73,0.18,35.17-16.63,35.17-40.33v-79.29 h20.06V564c0,34.58-18.34,58.66-55.23,58.66C1353.11,622.66,1333.81,598.59,1333.81,564"/>
      <polygon class="st0" points="1470.98,619.99 1470.98,486.23 1493.91,486.23 1559.07,588.84 1560.03,588.84 1560.03,486.23 1580.47,486.23 1580.47,619.99 1557.54,619.99 1492.38,517.39 1491.43,517.39 1491.43,619.99"/>
      <polygon class="st0" points="1606.62,619.99 1606.62,486.23 1706.56,486.23 1706.56,503.05 1627.07,503.05 1627.07,546.43 1699.87,546.43 1699.87,563.24 1627.07,563.24 1627.07,619.99"/>
      <polygon class="st0" points="1765.2,619.99 1765.39,503.05 1721.06,503.05 1721.06,486.23 1829.59,486.23 1829.78,503.05 1785.63,503.05 1785.63,619.99"/>
      <path class="st0" d="M1839.31,582.93h20.45c1.71,16.24,14.52,23.88,34.2,23.88c19.69,0,32.49-8.78,32.49-22.92 c0-10.51-8.61-17.01-30-22.75l-10.89-2.86c-30.38-8.41-43.75-19.31-43.75-37.26c0-22.55,20.06-37.45,51.58-37.45 c31.54,0,48.93,13.75,50.64,37.64h-20.43c-1.34-13.56-12.81-21.79-30.21-21.79c-18.91,0-31.15,7.66-31.15,20.07 c0,10.32,7.46,17.2,29.25,23.11l10.5,2.87c30.97,8.61,44.92,19.1,44.92,37.07c0,24.65-20.25,40.13-52.94,40.13 C1860.34,622.68,1841.04,607.95,1839.31,582.93"/>
      <polygon class="st1" points="986.65,810.2 947.48,676.44 967.91,676.44 997.73,783.46 998.68,783.46 1028.5,676.44 1049.32,676.44 1078.94,783.46 1080.08,783.46 1109.89,676.44 1130.14,676.44 1091.16,810.2 1069.77,810.2 1039.58,704.35 1038.43,704.35 1007.84,810.2"/>
      <polygon class="st1" points="1146.35,810.2 1146.35,676.44 1246.29,676.44 1246.29,693.25 1166.81,693.25 1166.81,733.96 1239.8,733.96 1239.8,750.59 1166.81,750.59 1166.81,793.39 1246.29,793.39 1246.29,810.2"/>
      <path class="st1" d="M1289.27,737.79h37.44c17.39,0,26.96-6.69,26.96-22.36c0-15.66-9.57-22.17-26.96-22.17h-37.44V737.79z M1268.82,810.2V676.44h58.86c29.61,0,46.23,12.63,46.23,38.99c0,20.83-10.89,33.44-30.2,37.07l29.06,57.71h-20.63l-28.1-55.6 h-34.77v55.6H1268.82z"/>
      <polygon class="st1" points="1396.91,810.2 1396.91,676.44 1417.36,676.44 1417.36,740.64 1418.32,740.64 1477.16,676.44 1501.05,676.44 1440.11,742.94 1501.05,810.59 1477.16,810.59 1418.32,745.24 1417.36,745.24 1417.36,810.2"/>
      <path class="st1" d="M1507.63,773.14h20.44c1.73,16.23,14.53,23.88,34.2,23.88c19.69,0,32.49-8.79,32.49-22.94 c0-10.5-8.6-17-30-22.74l-10.89-2.85c-30.39-8.4-43.77-19.3-43.77-37.27c0-22.55,20.09-37.45,51.61-37.45 c31.51,0,48.91,13.75,50.64,37.65h-20.44c-1.35-13.57-12.81-21.8-30.2-21.8c-18.93,0-31.15,7.67-31.15,20.07 c0,10.33,7.44,17.2,29.23,23.13l10.51,2.86c30.95,8.62,44.9,19.11,44.9,37.08c0,24.64-20.25,40.13-52.94,40.13 C1528.65,812.89,1509.34,798.16,1507.63,773.14"/>
      <polygon class="st1" points="1669.4,810.2 1669.58,693.25 1625.26,693.25 1625.26,676.44 1733.78,676.44 1733.98,693.25 1689.84,693.25 1689.84,810.2"/>
      <path class="st1" d="M1766.47,758.42h50.07l-24.47-61.14h-1.13L1766.47,758.42z M1724.62,810.2l54.46-133.77h24.84l54.46,133.77 h-20.83l-14.53-35.16h-63.23l-14.52,35.16H1724.62z"/>
      <polygon class="st1" points="1893.15,810.2 1893.35,693.25 1849.02,693.25 1849.02,676.44 1957.55,676.44 1957.75,693.25 1913.62,693.25 1913.62,810.2"/>
      <polygon class="st1" points="2012.35,810.2 2012.55,693.25 1968.21,693.25 1968.21,676.44 2076.75,676.44 2076.94,693.25 2032.81,693.25 2032.81,810.2"/>
      <path class="st2" d="M2144.37,770.27h63.64v-78.91h-1.15L2144.37,770.27z M2208.2,810.2v-29.23h-75.48v-11.66l72.62-92.87h15.28 v93.64h21.03v10.9h-21.03v29.23H2208.2z"/>
      <rect x="2264.95" y="792.44" class="st2" width="14.32" height="17.77"/>
      <path class="st2" d="M2400.94,744.48v-2.11c0-28.1-13.56-57.71-43.57-57.71c-29.62,0-43.17,29.61-43.17,57.71v2.11 c0,29.41,13.55,57.5,43.17,57.5C2387.19,801.99,2400.94,773.89,2400.94,744.48 M2301.58,744.28v-1.53 c0-34.2,17.59-68.98,55.79-68.98c38.99,0,56.19,35.35,56.19,68.98v1.53c-0.2,35.75-17.78,68.6-56.19,68.6 C2319.16,812.87,2301.58,779.83,2301.58,744.28"/>
      <polygon class="st0" points="785.78,453.66 561.28,583.27 561.28,324.04 280.65,486.07 336.78,518.47 505.16,421.26 505.16,615.68 561.28,648.08 841.91,486.06"/>
      <polygon class="st2" points="785.78,518.47 785.78,842.51 841.91,810.1 841.91,486.06"/>
      <polygon class="st2" points="336.78,518.47 336.78,842.51 280.65,810.1 280.65,486.06"/>
      <polygon class="st1" points="561.28,648.09 336.78,777.7 336.78,842.51 561.28,712.89 785.78,842.51 785.78,777.7"/>
    </svg>
  </div>
</template>


<script>
export default {
  setup () {
    const goBw = () => window
      .open('https://www.transformationswissen-bw.de', '_blank')
      .focus()

    const goIfa = () => window
      .open('https://zkw-inno.de', '_blank')
      .focus()

    return {
      goBw,
      goIfa
    }
  }
}
</script>


<style lang="scss" scoped>
  .brands {
    position: absolute;
    left: 1.5rem;
    top: 0;
    height: 11vh;
    z-index: 10;
  }

  svg.bw {
    height: 11vh;
    margin-right: 2.5rem;
    cursor: pointer;

    .st0 {
      fill:#111;
    }

    .st1 {
      fill:#5b6e7f;
    }
  }

  svg.ifa {
    height: 11vh;
    transform: scale(1.25);
    cursor: pointer;

    .st0{fill:#AECC53;}
    .st1{fill:#003E75;}
    .st2{fill:#7E8EB6;}
  }
</style>
