<template>
  <transition name="fade" appear>
    <div class="tp-container">
      <div class="tp">
        <svg viewBox="0 0 512 512">
          <polygon points="448,224 288,224 288,64 224,64 224,224 64,224 64,288 224,288 224,448 288,448 288,288 448,288 "/>
        </svg>
      </div>
    </div>
  </transition>
</template>


<style scoped lang="scss">
.tp-container {
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
}

.tp {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffb413;
  top: 0%;
  left: 0%;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &::before,
  &::after {
    content:'';
    display:block;
    position:absolute;
    top:0; right:0; bottom:0; left:0;
    border-radius:50%;
    border: 0.05rem solid #ffb413;
    pointer-events: none;
    backface-visibility: hidden;
    perspective: 1000;
  }

  &::before {
    animation: ripple 2s linear infinite;
  }

  &::after {
    animation: ripple 2s linear 1s infinite;
  }

  &:hover {
    background-color: #333;

    &::before,
    &::after {
      border: none;
      animation: none;
    }

    svg {
      polygon {
        fill: #fff;
      }
    }
  }

  svg {
    width: 60%;
    height: 60%;
    transition: transform 250ms ease-in-out !important;

    polygon {
      fill: #333;
    }
  }
}

@keyframes ripple{
  0% {
    transform: scale3D(1, 1, 1);
  }
  75% {
    transform: scale3D(1.75, 1.75, 1.75);
    opacity: 1;
  }
  100% {
    transform: scale3D(2, 2, 2);
    opacity: 0;
  }
}
</style>
